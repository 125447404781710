import React from 'react';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  NavLink,
  Route,
} from 'react-router-dom';

import {Link} from "@chakra-ui/react";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

import { ProtectedLayout } from './layouts/ProtectedLayout';
import MenuLayout from './layouts/MenuLayout';

import CreativePage from './views/creative/Creative';
import ProjectListPage from './views/projects/ProjectListPage';
import ProjectCalendarPage from './views/projects/ProjectCalendarPage';
import Login from './views/auth/login';
import NotAllowed from './views/auth/not-allowed';
import DashboardPage from './views/dashboard/DashboardPage';
import CreativeRatingsPage from './views/creative/ratings/RatingsPage';
import CreativeFinancesPage from './views/finances/creative/CreativeFinancesPage';
import AdminUsersListPage from './views/admin/AdminUsersListPage';
import ProfilePage from './views/admin-user/ProfilePage';
import ActiveCreativesPage from './views/creative/ActiveCreativesPage';
import NewApplicantCreativesPage from './views/creative/NewApplicantCreativesPage';
import InactiveCreativesPage from './views/creative/InactiveCreativesPage';
import ProjectPage from './views/projects/ProjectPage';
import RecruitmentPage from 'views/recruitment/RecruitmentPage';
import UnmatchedCitiesListPage from './views/settings/cities/UnmatchedCitiesListPage';
import packageInfo from '../package.json';
import config from './config';
import { useRouteError } from 'react-router-dom';
import { FormLayout } from 'layouts/FormLayout';
import ClientFinancesPage from 'views/finances/client/ClientFinancesPage';
import PhotoroomTemplatesPage from 'views/photoroom-templates/PhotoroomTemplatesPage';
import ClientPage from 'views/clients/ClientPage';
import ClientListPage from 'views/clients/ClientListPage';
import EditorListPage from 'views/editor/EditorListPage';
import EditorPage from 'views/editor/EditorPage';
import ClientUserListPage from 'views/client-user/ClientUserListPage';
import ClientUserPage from 'views/client-user/ClientUserPage';


Bugsnag.start({
  apiKey: 'aea3f1ee975cefb7f7b71220b972acab',
  plugins: [new BugsnagPluginReact()],
  appVersion: packageInfo.version,
  releaseStage: config.environment,
  enabledReleaseStages: ['production', 'staging', 'testing'],
});
BugsnagPerformance.start({ apiKey: 'aea3f1ee975cefb7f7b71220b972acab' });

// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorBoundary = () => {
  const error = useRouteError() as Error;

  error && console.error(error);
  if (error && error instanceof Error) {
    Bugsnag.notify(error);
  }

  return (
    <section>
      <h1>Error occurred</h1>
      <Link as={NavLink} to='/'>Go back home</Link>
      <details>
        <summary>
          <h3>Error details:</h3> {error?.name}
        </summary>
        <pre>
          {error?.message}
          {error?.stack}
        </pre>
      </details>
    </section>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<FormLayout />} errorElement={<ErrorBoundary />}>
      <Route path={'/intel'} element={<ProtectedLayout />}>
        <Route path={`creatives/active`} element={<ActiveCreativesPage />} />
        <Route path={`creatives/inactive`} element={<InactiveCreativesPage />} />
        <Route path={`creatives/new-applicant`} element={<NewApplicantCreativesPage />} />
        <Route path={`creative/:creativeId`} element={<MenuLayout page={<CreativePage />} />} />
        <Route path={'creatives/ratings'} element={<CreativeRatingsPage />} />
        <Route path={`projects/list`} element={<ProjectListPage />} />
        <Route path={`projects/calendar`} element={<ProjectCalendarPage />} />
        <Route path={`project/:projectId`} element={<ProjectPage />} />
        <Route path={'settings/admins'} element={<AdminUsersListPage />} />
        <Route path={'settings/recruitment'} element={<RecruitmentPage />} />
        <Route path={'settings/photoroom-templates'} element={<PhotoroomTemplatesPage />} />
        <Route path={'settings/cities'} element={<UnmatchedCitiesListPage />} />
        <Route path={'editors'} element={<EditorListPage />} />
        <Route path={'editor/:editorId'} element={<EditorPage />} />
        <Route path={'settings/recruitment'} element={<RecruitmentPage />} />
        <Route path={'clients/users'} element={<ClientUserListPage />} />
        <Route path={'client/user/:userId'} element={<ClientUserPage />} />
        <Route path={'dashboard'} element={<DashboardPage />} />
        <Route path={'clients/companies'} element={<ClientListPage />} />
        <Route path={'client/:clientId'} element={<ClientPage />} />
        <Route path={'profile'} element={<ProfilePage />} />
        <Route path={'finances/creative'} element={<CreativeFinancesPage />} />
        <Route path={'finances/client'} element={<ClientFinancesPage />} />
      </Route>
      <Route path={'/login'} element={<Login />} />
      <Route path={'/no-permission'} element={<NotAllowed />} />
      <Route path='*' element={<Navigate to='/intel/projects/list' replace />} />
    </Route>,
  ),
);
