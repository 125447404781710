import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Link, Text } from '@chakra-ui/react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useGetEditors } from 'api/editors';
import { EditorListFilterTypes } from './types';
import { Cell } from 'react-table';
import DevelopmentTable from 'components/DevelopmentTable';
import EditorListFilters from './components.tsx/EditorListFilters';
import { EditorStatusLabels } from './constants';
import EditorListMenu from './components.tsx/EditorListMenu';
import useCountryList from 'hooks/useCountryList';

export default function EditorList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    search = '',
    status = '',
    country= '',
  } = Object.fromEntries(queryParams.entries());

  const [
    { data: editorsData, loading, error },
    getEditors,
  ] = useGetEditors();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const {getCountryName} = useCountryList();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 25);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'name',
    order: parseInt(searchParams.get('sortOrder')) || 1,
  });
  const [filters, setFilters] = useState<EditorListFilterTypes>({
    search,
    status,
    country,
  });

  const callGetEditors = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: EditorListFilterTypes,
    ) => {
      try {
        await getEditors({
          params: {
            page,
            perPage,
            sorting,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getEditors],
  );

  useEffect(() => {
    callGetEditors(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);


  const handleSearch =( updatedFilters?: EditorListFilterTypes)=> {
    callGetEditors(page, perPage, sorting, updatedFilters || filters);
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, encodeURIComponent(value));
      });
      return params;
    });
  }


  const columnsDataEditors = {
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
    },
    phone: {
      columnDef: {
        Header: 'Phone',
        accessor: 'phone',
      },
    },
    status: {
      columnDef: {
        Header: 'Status',
        accessor: 'status',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {EditorStatusLabels[cell.value]}
          </Text>
        );
      },
    },
    country: {
      columnDef: {
        Header: 'Country',
        accessor: 'country',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {getCountryName(cell.value)}
          </Text>
        );
      },
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Link as={NavLink} to={`/intel/editor/${cell.value}`}>
            <Button variant='photonLight'>View</Button>
          </Link>
        );
      },
    },
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <DevelopmentTable
            variant='underlineRows'
            menuComp={<EditorListMenu onRefresh={handleSearch}/>}
            label={'Editors'}
            columnsData={Object.values(columnsDataEditors).map(
              (column: any) => column.columnDef,
            )}
            renderersData={columnsDataEditors}
            tableData={editorsData?.data}
            total={editorsData?.count}
            filterComp={
              <EditorListFilters
                filters={filters}
                setFilters={setFilters}
                handleSearch={handleSearch}
              />
            }
            isLoading={loading}
            sorting={sorting}
            setSorting={setSorting}
            setPage={setPage}
            setPerPage={setPerPage}
          />
    </Box>
  );
}
