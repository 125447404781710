import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    useToast,
    Flex,
    Select,
  } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {useEffect, useState} from 'react';
import { useFormContext } from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import { useUpdateEditor } from 'api/editors';
import { MdOutlineDone } from "react-icons/md";
import { IoCloseSharp } from "react-icons/io5";
import { CustomFieldStyle } from 'views/projects/variables/styles';
import { EditorStatusLabels } from '../constants';
import { CustomField } from 'views/projects/components/ProjectBasicData';


  export default function EditorBasicData(props: { editorData: any }) {
    const { setIsFormDirty, shouldReset, setShouldReset } = useFormContext();
    const [editField, setEditField] = useState('');
    const { editorData } = props;
    const { editorId } = useParams();
    const toast = useToast();
    const {countryList} = useCountryList();

  
    const defaultValues = {
      name: editorData.name,
      email: editorData.email,
      phone: editorData.phone,
    };
  
    const {
      handleSubmit,
      register,
      formState: {isDirty },
      reset,
      getValues,
    } = useForm({
      defaultValues,
    });
  
    const [
      {
        error: updateEditorError,
        loading
      },
      updateEditor,
    ] = useUpdateEditor(editorId as string);
  
    useEffect(
      () => {
        if (updateEditorError) {
          toast({
            title: 'Sikertelen mentés',
            status: 'error',
            duration: 3000,
          });
        }
      },
      [updateEditorError]
    );
  
    const handleSaveClick = async (data: any) => {
      await updateEditor({data});
      setIsFormDirty(false);
      toast({
        title: 'Sikeres mentés',
        status: 'success',
        duration: 3000,
      });
    };
  
    useEffect(() => {
      if (isDirty) {
        setShouldReset(false);
        setIsFormDirty(isDirty);
      }
    }, [isDirty]);
  
    useEffect(() => {
      if (shouldReset) reset(defaultValues);
    }, [shouldReset]);
  

  
    return <Box mt='50px'>
      <form onSubmit={handleSubmit(handleSaveClick)}>
        <Flex
          justifyContent='space-around'
          alignItems={{ base: 'center', md: 'start' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex direction='column'>
            <CustomField
              label='Name'
              id='name'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
            />
            <CustomField
              label='Email'
              id='email'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
            />
            <CustomField
              label='Phone'
              id='phone'
              register={register}
              getValues={getValues}
              setEditField={setEditField}
              editField={editField}
            />
            <Flex justifyContent={'space-between'}>
              <FormLabel>Email confirmed</FormLabel>
              {editorData.emailConfirmed ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
            <Flex justifyContent={'space-between'}>
              <FormLabel>Newsletter</FormLabel>
              {editorData.newsletter ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
            <Flex justifyContent={'space-between'}>
              <FormLabel>Privacy Notice</FormLabel>
              {editorData.pn ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
            <Flex justifyContent={'space-between'}>
              <FormLabel>Terms & Conditions</FormLabel>
              {editorData.tandc ? <MdOutlineDone/> : <IoCloseSharp />}
            </Flex>
          </Flex>
        </Flex>
        <Flex justifyContent='center' m='50px'>
          <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Box>;
  }
  