import React, { useState } from 'react';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import { useGetEditor } from 'api/editors';
import EditorBillingData from './components.tsx/EditorBilling';
import EditorBasicData from './components.tsx/EditorBasicData';
import { EditorStatusLabels } from './constants';

const tabLabels = [
  'Basic data',
  'Billing',
];

export default function Editor() {
  const { handleTabChange } = useFormContext();
  const { editorId } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {getCountryName} = useCountryList();

  const [{ data: editorData }, getEditor] = useGetEditor(editorId);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {editorData && (
        <>
          <Flex
            bg='white'
            alignItems='center'
            w={{ base: '90vw', md: '500px' }}
            borderRadius='20px'
            m='10px'
          >
            <Box m='10px 20px' w='80%'>
              <Text fontSize='25px' fontWeight='bold'>
                {editorData.name}
              </Text>
              <Text>{editorData.email}</Text>
              <Text>{editorData.phone}</Text>
              <Flex justifyContent='space-between' w='90%' alignItems='center'>
                <Text>{getCountryName(editorData.editor.billing?.address?.country)}</Text>
              </Flex>
            </Box>
            <Text w= '150px' alignSelf='end' mb='10px'>{EditorStatusLabels[editorData.status]}</Text>
          </Flex>
          <Tabs index={selectedTabIndex}>
            <TabList ml='0px' mr='20px'>
              {tabLabels.map((label, index) => (
                <Tab
                  key={index}
                  w={{ base: '50%', md: 'auto' }}
                  onClick={() => handleTabChange(index, setSelectedTabIndex)}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <EditorBasicData editorData={editorData} />
              </TabPanel>
              <TabPanel>
                <EditorBillingData editorData={editorData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
