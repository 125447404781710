import React, { useState } from 'react';
import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Link,
  Button,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useGetClient } from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import ClientBillingData from './components/ClientBillingData';
import ClientBasicData from './components/ClientBasicData';
import ClientManage from './components/ClientManage';
import { projectCategoryLabels } from './constants';

export default function Client() {
  const { clientId } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {getCountryName} = useCountryList();


  const [{ data: clientData, loading: getClientLoading, error: getClientError }, getClientData] =
    useGetClient(clientId);

  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {clientData && (
        <>
            <Flex justifyContent='space-between' alignItems='center'>
              <Flex
              bg='white'
              alignItems='center'
              w={{ base: '90vw', md: '500px' }}
              borderRadius='20px'
              m='10px'
            >
              <Box m='10px 20px' w='80%'>
                <Text fontSize='25px' fontWeight='bold'>
                  {clientData.name}
                </Text>
                <Text>{clientData.email}</Text>
                <Text>{clientData.phone}</Text>
                  <Text>{getCountryName(clientData.country)}</Text>
                  <Text>category: {projectCategoryLabels[clientData.industryCategory]}</Text>
              </Box>
            </Flex>
            {
              clientData.adminVerified &&  
                <Link href={clientData.storage?.oauth?.url} _hover={{ textDecoration: 'none' }}>
                  <Button as="a" variant='photonDark'>Client drive</Button>
                </Link>
            }
            </Flex>
          <Tabs index={selectedTabIndex} onChange={handleTabChange}>
            <TabList ml='0px' mr='20px'>
              <Tab>Basic data</Tab>
              <Tab>Billing</Tab>
              <Tab>Manage</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ClientBasicData clientData={clientData} />
              </TabPanel>

              <TabPanel>
                <ClientBillingData clientData={clientData} />
              </TabPanel>

              <TabPanel>
                <ClientManage clientData={clientData} getClientData={getClientData}/>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
