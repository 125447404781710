export const EditorStatus: Record<string, string> = {
    ACTIVE: 'activeUser',
    JUST_REGISTERED: 'justRegUser'
  };

  export const EditorStatusLabels = {
    [EditorStatus.ACTIVE]: 'Active',
    [EditorStatus.JUST_REGISTERED]: 'Just registered',
  };


