import {
    Box,
    FormLabel,
    Flex,
  } from '@chakra-ui/react';


export const CustomFieldStyle = {
  alignItems: 'center',
  bg: 'white',
  borderRadius: '15px',
  p: '5px',
  pl: '15px',
  h: '2.5rem',
  mb: 4,
  w: { base: '100%', lg: '500px' },
};
  
  
  export default function EditorBillingData(props: { editorData: any }) {
    const { editorData } = props;
  
    return <Box mt='50px'>
        <Flex
          justifyContent='space-around'
          alignItems={{ base: 'center', md: 'start' }}
          direction={{ base: 'column', md: 'row' }}
        >
          <Flex direction='column' w='500px'>
            <>
              <FormLabel>Billing name</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.name}
              </Flex>
            </>

            <>
              <FormLabel>Billing country</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.address?.country}
              </Flex>
            </>

            <>
              <FormLabel>ZIP code</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.address?.zipCode}
              </Flex>
            </>

            <>
              <FormLabel>City</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.address?.city}
              </Flex>
            </>

            <>
              <FormLabel>Street</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.address?.street}
              </Flex>
            </>

            <>
              <FormLabel>Billing language</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.lang === 'hu' ? 'Hungarian': 'English'}
              </Flex>
            </>

        </Flex>
          <Flex direction='column' w='500px'>
            <>
              <FormLabel>Tax number</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.taxNumber}
              </Flex>
            </>

            <>
              <FormLabel>GIRO</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.bankAccountGiro}
              </Flex>
            </>

            <>
              <FormLabel>Swift</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.bankSwift}
              </Flex>
            </>

            <>
              <FormLabel>IBAN</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.bankAccountIban}
              </Flex>
            </>

            <>
              <FormLabel>Bank name</FormLabel>
              <Flex {...CustomFieldStyle}>
                {editorData.editor.billing?.bankName}
              </Flex>
            </>

          </Flex>
        </Flex>
    </Box>;
  }
  