
import React, { useEffect, useState } from 'react';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Switch,
  Link,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {  useParams } from 'react-router-dom';
import { useActivateClient, useGetClientUserEmails, useUpdateClient } from 'api/clients';

export default function ClientManage(props: { clientData: any , getClientData: Function}) {
  const { clientData, getClientData } = props;
  const { clientId } = useParams();
  const toast = useToast();
  const [allowWoltSpecificEmails, setAllowWoltSpecificEmails] = useState(!!clientData.emails)
  const defaultValues = {
    chatEnabled: clientData.chatEnabled,
    logo: clientData.logo
  };

  const {
    handleSubmit,
    register,
    watch
  } = useForm({
    defaultValues,
  });

  const [{ error: updateClientError, loading }, updateClient] = useUpdateClient(clientId);
  const [{ error: activateClientError, loading: activateLoading }, activateClient] = useActivateClient(clientId);
  const [{data: clientEmails, error: getClientEmailError }] = useGetClientUserEmails(clientId);

  const handleSaveClick = async (data: any) => {
    try{
      await updateClient({data: {...data, allowWoltSpecificEmails}});
      toast({
          title: 'Company updated',
          status: 'success',
          duration: 3000,
      });
    }catch{}
  };

  const handleActivateClick = async () => {
    try{
      await activateClient();
      toast({
          title: 'Company activated',
          status: 'success',
          duration: 3000,
      });
      getClientData()
    }catch{}
  };

  useEffect(
    () => {
      if (updateClientError || activateClientError) {
        toast(
          {
            title: 'Update is unsuccessful!',
            status: 'error',
            duration: 3000,
          }
        )
      }
    },
    [updateClientError, toast]
  );

  return (
    <Flex justifyContent='center' >
      <form onSubmit={handleSubmit(handleSaveClick)}>
        {
          !clientData.adminVerified && 
          <Flex justifyContent='center' mb={4}>
            <Button isLoading={activateLoading} onClick={()=>handleActivateClick()} variant='photonLight' w='200px'>
              Activate
            </Button>
          </Flex>
        }
        <Flex direction ='column' alignItems='center' w={{base: '90vw', md:'500px'}}>
          <Flex justifyContent='start' w='100%'>
              <Flex id='chatEnabled' mb={4}>
                <FormLabel>Chat enabled</FormLabel>
                <Switch defaultChecked={clientData.chatEnabled} ml='10px' {...register('chatEnabled')}></Switch>
              </Flex>
          </Flex>
          <Flex justifyContent='start' w='100%'>
              <Flex id='chatEnabled' mb={4}>
                <FormLabel>Send Wolt-specific emails to the on-site contact persons</FormLabel>
                <Switch 
                  defaultChecked={allowWoltSpecificEmails} 
                  ml='10px' 
                  onChange ={(e)=>setAllowWoltSpecificEmails(e.target.checked)}
                >
                </Switch>
                <Flex direction='column'>
                </Flex>
              </Flex>
          </Flex>
          <Flex direction='column' w={{base: '90vw', md:'500px'}}>
            <FormControl id='logo' mb={4}>
              <FormLabel>Set logo for the company</FormLabel>
              <Input variant='main' {...register('logo')} placeholder='Logo URL'/>
            </FormControl>
            <Flex justifyContent ='center'>
              <Link
                href={`mailto:${'hello@photon.rocks'}?bcc=${clientEmails?.join(',')}`}
              >
                <Button variant='photonMedium' w='320px'> 
                  Send emails to all useres in company
                </Button>
              </Link>
            </Flex>
          </Flex>
        </Flex>
        


        <Flex justifyContent='center' m='50px'>
          <Button isLoading={loading} type='submit' variant='photonDark' w='200px'>
            Save
          </Button>
        </Flex>
      </form>
    </Flex>
  );
}

