import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Flex, Text, Link } from '@chakra-ui/react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useGetClients } from 'api/clients';
import { ClientListFilterTypes } from './types';
import { columnsDataClients } from './variables/columnsData';
import ClientListTable from './components/ClientListTable';
import DevelopmentTable from 'components/DevelopmentTable';
import { Cell } from 'react-table';
import ClientListFilters from './components/ClientListFilters';
import moment from 'moment';
import useCountryList from 'hooks/useCountryList';

export default function ClientList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { search = '', country = '', status = ' ', industry = '' } = Object.fromEntries(queryParams.entries());


  const [
    { data: clientsData, loading: getClientsLoading, error: getClientsError },
    getClients,
    cancelGetClients,
  ] = useGetClients();

  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const {getCountryName} = useCountryList();
  const [perPage, setPerPage] = useState(10);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'name',
    order: parseInt(searchParams.get('sortOrder')) || 1,
  });
  const [filters, setFilters] = useState<ClientListFilterTypes>({
    search,
    country,
    status,
    industry
  });

  const callGetClients = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ClientListFilterTypes,
    ) => {
      try {
         const transformedFilters = Object.fromEntries(
          Object.entries(filters).map(([key, value]) => [key, value === '' ? undefined : value])
        );
        
        await getClients({
          params: {
            page,
            perPage,
            sorting,
            filters: transformedFilters,
          },
        });

      } catch (e) {
        console.log(e);
      }
    },
    [getClients],
  );

  const handleSearch = (filters: ClientListFilterTypes)=> {
    callGetClients(page, perPage, sorting, filters);
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, encodeURIComponent(value as any));
      });
      return params;
    });
  }

  const handleFilterChange = (value: string | Date, name: keyof ClientListFilterTypes) => {
    const updatedFilters: Partial<ClientListFilterTypes> = { ...filters, [name]: value };
    setFilters(updatedFilters as ClientListFilterTypes);

    if (name !== 'search' || !value) {
      handleSearch(updatedFilters);
    }
  };

  useEffect(() => {
    callGetClients(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);
  const columnsDataClientUsers = {
    name: {
      columnDef: {
        Header: 'Company',
        accessor: 'name',
      },
    },
    country: {
      columnDef: {
        Header: 'Country',
        accessor: 'country',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {getCountryName(cell.value)}
          </Text>
        );
      },
    },
    industryCategory: {
      columnDef: {
        Header: 'Industry',
        accessor: 'industryCategory',
      },
    },
    'adminVerified.verifiedAt': {
      columnDef: {
        Header: 'Date of application',
        accessor: 'adminVerified.verifiedAt',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {moment(cell.value).format('DD.MM.YYYY HH:mm')}
          </Text>
        );
      },
    },
    'adminVerified.verifiedByName': {
      columnDef: {
        Header: 'Onboarding person',
        accessor: 'adminVerified.verifiedByName',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>{cell.value}</Text>
        );
      },
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Link as={NavLink} to={`/intel/client/${cell.value}`}>
            <Button variant='photonLight'>View</Button>
          </Link>
        );
      },
    },
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <DevelopmentTable
            variant='underlineRows'
            label={'Companies'}
            columnsData={Object.values(columnsDataClientUsers).map(
              (column: any) => column.columnDef,
            )}
            renderersData={columnsDataClientUsers}
            tableData={clientsData?.data}
            total={clientsData?.count}
            filterComp={
              <ClientListFilters
                filters={filters}
                setFilters={setFilters}
                handleSearch={handleSearch}
                handleFilterChange={handleFilterChange}
              />
            }
            isLoading={getClientsLoading}
            sorting={sorting}
            setSorting={setSorting}
            setPage={setPage}
            setPerPage={setPerPage}
          />
    </Box>
  );
}
