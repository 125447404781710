import React, { useState } from 'react';
import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'contexts/useFormContext';
import useCountryList from 'hooks/useCountryList';
import { useGetClientUser } from 'api/client-users';
import ClientUserBasicData from './components/ClientUserBasicData';
import { UserStatusLabels } from './constants';
import ClientUserManage from './components/ClientUserManage';

export default function ClientUser() {
  const { handleTabChange } = useFormContext();
  const { userId } = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const {getCountryName} = useCountryList();

  const [{ data: clientUserData }, GetClientUser] = useGetClientUser(userId);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {clientUserData && (
        <>
          <Flex
            bg='white'
            alignItems='center'
            w={{ base: '90vw', md: '500px' }}
            borderRadius='20px'
            m='10px'
          >
            <Box m='10px 20px' w='80%'>
              <Text fontSize='25px' fontWeight='bold'>
                {clientUserData.name}
              </Text>
              <Text>{clientUserData.email}</Text>
              <Text>{clientUserData.phone}</Text>
              <Flex justifyContent='space-between' w='90%' alignItems='center'>
                <Text>{getCountryName(clientUserData.billingAddressObj?.country)}</Text>
              </Flex>
            </Box>
            <Text w='150px' alignSelf='end' mb='10px'>{UserStatusLabels[clientUserData.status]}</Text>

          </Flex>
          <Tabs>
            <TabList ml='0px' mr='20px'>
              <Tab>Basic data</Tab>
              <Tab>Manage</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
              <ClientUserBasicData clientUserData={clientUserData} />
              </TabPanel>
              <TabPanel>
                <ClientUserManage clientUserData={clientUserData} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Box>
  );
}
