import React, { useEffect, KeyboardEvent } from 'react';

import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Text,
  Box,
  Select,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import { TiDeleteOutline } from 'react-icons/ti';
import { useSearchParams } from 'react-router-dom';
import { EditorListFilterTypes } from '../types';
import { EditorStatus, EditorStatusLabels } from '../constants';
import useCountryList from 'hooks/useCountryList';
import { useGetEditorCountries } from 'api/editors';


const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20' />
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<EditorListFilterTypes>>,
  handleSearch: (updatedFilters: EditorListFilterTypes) => Promise<void>,
) => {
  const updatedFilters = { search: '', status: '', country: ''};
  await setFilters(updatedFilters);
  await handleSearch(updatedFilters);
};

const EditorListFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
  filters: EditorListFilterTypes;
  handleSearch: any;
  setFilters: React.Dispatch<React.SetStateAction<EditorListFilterTypes>>;
}) => {
  const {getCountryName} = useCountryList();
  const [{ data: editorCountries }] = useGetEditorCountries();

  const handleFilterChange = (value: string, name: keyof EditorListFilterTypes) => {
    const updatedFilters = { ...filters, [name]: value };
    setFilters(updatedFilters as EditorListFilterTypes);

    if (name !== 'search' || !value) {
      handleSearch(updatedFilters as EditorListFilterTypes);
    }
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  return (
    <Box w='100%'>
      <Grid
        templateColumns='repeat(auto-fit, minmax(320px, 1fr))'
        gap='0px 0px'
        justifyItems='center'
        alignItems='center'
        p='0px'
      >
        <Flex direction='column' mb={4}>
          <Text>Name/email:</Text>
          <InputGroup w='300px'>
            {filters.search && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'} />
            )}
            <Input
              variant='tableSearch'
              placeholder='Search by name'
              onChange={(e) => handleFilterChange(e.target.value, 'search')}
              value={filters.search}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>


        <Flex direction='column' mb={4} width='300px'>
          <Text>Status:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'status')}
            placeholder='Select status'
            value={filters.status}
          >
            {Object.values(EditorStatus).map((s)=>(
                <option key={s} value={s}>
                     {EditorStatusLabels[s]}
                </option>                
            ))}

          </Select>
        </Flex>
        <Flex direction='column' mb={4} width='300px'>
          <Text>Country:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'country')}
            placeholder='Select country'
            value={filters.country}
          >
            {editorCountries?.map((country: string) => (
              <option key={country} value={country}>
                {getCountryName(country as unknown as string)}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex justifyContent={{ base: 'center', md: 'start' }} w='300px'>
          <Button
            onClick={() => handleResetFilters(setFilters, handleSearch)}
            variant='photonMedium'
            w='130px'
          >
            Reset filters
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};
export default EditorListFilters;
