export const UserStatus: Record<string, string> = {
    JUST_REGISTERED: 'justRegUser',
    ACTIVE: 'activeUser',
  };

  export const UserStatusLabels = {
    [UserStatus.JUST_REGISTERED]: 'Just registered',
    [UserStatus.ACTIVE]: 'Active',
  };


  export const UserRoles = {
    OWNER: 'ownerPlus',
    MANAGER: 'managerPlus',
    ADMIN: 'adminPlus'
  }

  export const UserRoleLabels = {
    [UserRoles.OWNER]: 'Owner',
    [UserRoles.MANAGER]: 'Manager',
    [UserRoles.ADMIN]: 'Admin',
  };