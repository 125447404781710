export const ProjectCategory = {
    EVENT: 'event',
    PORTRAIT: 'portrait',
    PROPERTY: 'property',
    WEDDING: 'wedding',
    FAMILY_AND_GROUP: 'familyAndGroup',
    FOOD: 'food',
    PRODUCT: 'product',
    CUSTOM: 'custom',
};

export const projectCategoryLabels = {
    [ProjectCategory.EVENT]: 'Event',
    [ProjectCategory.PORTRAIT]: 'Portrait',
    [ProjectCategory.PROPERTY]: 'Property',
    [ProjectCategory.WEDDING]: 'Wedding',
    [ProjectCategory.FAMILY_AND_GROUP]: 'Family and group',
    [ProjectCategory.FOOD]: 'Food',
    [ProjectCategory.PRODUCT]: 'Product',
    [ProjectCategory.CUSTOM]: 'Custom'
}


export const OrderAmountCategory = {
    LESS_THAN_15: 'lessThan15',
    BETWEEN_15_AND_25: '15to25',
    MORE_THAN_25: 'moreThan25',
    NOT_SURE: 'notSure',
    ONE_TIME: 'oneTime',
};

export const orderAmountCategoryLabels = {
    [OrderAmountCategory.LESS_THAN_15]: 'Less than 15',
    [OrderAmountCategory.BETWEEN_15_AND_25]: '15 to 25',
    [OrderAmountCategory.MORE_THAN_25]: 'More than 25',
    [OrderAmountCategory.NOT_SURE]: 'Not sure',
    [OrderAmountCategory.ONE_TIME]: 'One time',
}





