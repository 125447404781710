import useAxios from './basic/use-axios';

export const useGetEditors = () =>
  useAxios(
    {
      url: '/admin/editor/list/new',
      method: 'GET',
    },
    { manual: true },
  );

export const useGetEditor = (editorId: string) =>
  useAxios(
    {
      url: `/admin/editor/${editorId}/new`,
      method: 'GET',
    },
  );

export const useUpdateEditor = (editorId: string) =>
  useAxios(
    {
      url: `/admin/editor/${editorId}`,
      method: 'PATCH',
    },
    { manual: true },
  );

export const useCreateEditor = () =>
  useAxios(
    {
      url: `/admin/editor`,
      method: 'POST',
    },
    { manual: true },  
  );

export const useGetEditorCountries = () =>
  useAxios(
    {
      url: `/admin/editor/countries`,
      method: 'GET',
    },
  );