import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Link, Text } from '@chakra-ui/react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { Cell } from 'react-table';
import DevelopmentTable from 'components/DevelopmentTable';
import { useGetClientUsers } from 'api/client-users';
import { ClientUserListFilterTypes } from './types';
import ClientUserListFilters from './components/ClientUserListFilters';
import { UserRoleLabels, UserRoles, UserStatusLabels } from './constants';
import useCountryList from 'hooks/useCountryList';

export default function ClientUserList() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const {
    search= '',
    status= '',
    country= '',
    brand= '',
    role= '',
  } = Object.fromEntries(queryParams.entries());

  const [
    { data: editorsData, loading, error },
    getClientUsers,
  ] = useGetClientUsers();
  
  const [searchParams, setSearchParams] = useSearchParams();
  const {getCountryName} = useCountryList();
  const [page, setPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [perPage, setPerPage] = useState(parseInt(searchParams.get('perPage')) || 25);
  const [sorting, setSorting] = useState({
    field: searchParams.get('sort') || 'name',
    order: parseInt(searchParams.get('sortOrder')) || 1,
  });
  const [filters, setFilters] = useState<ClientUserListFilterTypes>({
    search,
    status,
    country,
    brand,
    role
  });

  const callGetClientUsers = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: ClientUserListFilterTypes,
    ) => {
      try {
        await getClientUsers({
          params: {
            page,
            perPage,
            sorting,
            filters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getClientUsers],
  );

  useEffect(() => {
    callGetClientUsers(page, perPage, sorting, filters);
  }, [page, perPage, sorting]);


  const handleSearch =( filters: ClientUserListFilterTypes)=> {
    callGetClientUsers(page, perPage, sorting, filters);
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, encodeURIComponent(value as any));
      });
      return params;
    });
  }


  const columnsDataClientUsers = {
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
    },
    phone: {
      columnDef: {
        Header: 'Phone',
        accessor: 'phone',
      },
    },
    company: {
        columnDef: {
          Header: 'Company',
          accessor: 'plus.brand.brandName',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Link as={NavLink} to={`/intel/client/${cell.value.plus.brand.id}`}>
              <Text fontWeight='bold' color='black'>{cell.value.plus.brand.brandName}</Text>
            </Link>
          );
        },
      },
      country: {
        columnDef: {
          Header: 'Country',
          accessor: 'country',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {getCountryName(cell.value)}
            </Text>
          );
        },
      },
    role: {
        columnDef: {
          Header: 'Role',
          accessor: 'role',
        },
        renderer: (cell: Cell<any, any>) => {
          return (
            <Text color='black' fontWeight='bold'>
              {UserRoleLabels[cell.value]}
            </Text>
          );
        },
      },
    status: {
      columnDef: {
        Header: 'Status',
        accessor: 'status',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text color='black' fontWeight='bold'>
            {UserStatusLabels[cell.value]}
          </Text>
        );
      },
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Link as={NavLink} to={`/intel/client/user/${cell.value}`}>
            <Button variant='photonLight'>View</Button>
          </Link>
        );
      },
    },
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <DevelopmentTable
            variant='underlineRows'
            label={'Client users'}
            columnsData={Object.values(columnsDataClientUsers).map(
              (column: any) => column.columnDef,
            )}
            renderersData={columnsDataClientUsers}
            tableData={editorsData?.data}
            total={editorsData?.count}
            filterComp={
              <ClientUserListFilters
                filters={filters}
                setFilters={setFilters}
                handleSearch={handleSearch}
              />
            }
            isLoading={loading}
            sorting={sorting}
            setSorting={setSorting}
            setPage={setPage}
            setPerPage={setPerPage}
          />
    </Box>
  );
}
